export default AnimatedGroup;

import React from "react";
import { animated, useInView } from "@react-spring/web";

function AnimatedGroup({ children }: { children: React.ReactNode }) {
  const [ref, springs] = useInView(() => ({
    from: {
      opacity: 0,
      y: 80,
    },
    to: {
      opacity: 1,
      y: 0,
    },
  }));
  return (
    <animated.div ref={ref} style={springs}>
      {children}
    </animated.div>
  );
}
